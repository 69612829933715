// IMPORTS
import React, { Component } from "react";
import { View, TouchableOpacity } from "react-native";
import SpriteSheet from "../libraries/spriteSheet";
import { sprites } from "../libraries/variables";
import { styles } from "../styles/componentStyles";
// COMPONENT
export default class Mole extends Component {
  constructor(props) {
    super(props);
    this.mole = null;
    this.attackTimeout = null;
    this.frozen = false;
    this.isPopping = false;
    this.hasPopped = false;
    this.isWhacked = false;
    this.isAttacking = false;
    this.hasAttacked = false;
    this.attackSpeed = 1000;
  }
  // FREEZE
  freeze = () => {
    this.frozen = true;
    this.mole.stop(() => {
      clearTimeout(this.attackTimeout);
    });
  };
  // UNFREEZE
  unfreeze = () => {
    this.frozen = false;
    if (this.isPopping) {
      this.appear();
    } else if (this.hasPopped) {
      if (this.hasAttacked) {
        this.hide();
      } else {
        this.attack();
      }
    }
  };
  // HIDE
  hide = () => {
    clearTimeout(this.attackTimeout);
    this.mole.play({
      type: "hide",
      fps: 24,
      onFinish: () => {
        this.hasPopped = false;
        this.hasAttacked = false;
      },
    });
  };
  // ATTACK
  attack = () => {
    this.attackTimeout = setTimeout(() => {
      this.isAttacking = true;
      this.props.damagePlayer();
      this.mole.play({
        type: "attack",
        fps: 12,
        onFinish: () => {
          this.isAttacking = false;
          this.hasAttacked = true;
          this.hide();
        },
      });
    }, this.attackSpeed);
  };
  // APPEAR
  appear = () => {
    this.mole.play({
      type: "appear",
      fps: 24,
      onFinish: () => {
        this.isPopping = false;
        this.hasPopped = true;
        this.attack();
      },
    });
  };
  // POP
  pop = () => {
    this.isPopping = true;
    this.hasPopped = false;
    this.isWhacked = false;
    this.isAttacking = false;
    this.hasAttacked = false;
    this.appear();
  };
  // WHACK
  whack = () => {
    if (this.hasPopped) {
      if (
        this.frozen ||
        this.isWhacked ||
        this.isAttacking ||
        this.hasAttacked
      ) {
        return;
      } else {
        if (this.attackTimeout) {
          clearTimeout(this.attackTimeout);
        }
        this.isWhacked = true;
        this.props.addPoint();
        this.mole.play({
          type: "dizzy",
          fps: 24,
          onFinish: () => {
            this.mole.play({
              type: "faint",
              fps: 24,
              onFinish: () => {
                this.isPopping = false;
                this.hasPopped = false;
              },
            });
          },
        });
      }
    }
  };
  // RENDER
  render() {
    return (
      <View>
        <SpriteSheet
          ref={(ref) => (this.mole = ref)}
          source={{
            image: sprites.moles,
            dimensions: { height: 1152, width: 1140 },
          }}
          columns={6}
          rows={8}
          width={100}
          animations={{
            idle: [0],
            appear: [1, 2, 3, 4],
            hide: [4, 3, 2, 1, 0],
            dizzy: [36, 37, 38],
            faint: [42, 43, 44, 0],
            attack: [11, 12, 13, 14, 15, 16],
          }}
        />
        <TouchableOpacity style={styles.moleButton} onPress={this.whack} />
      </View>
    );
  }
}
