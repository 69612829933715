// IMPORTS
import { StyleSheet } from "react-native";
import { colors } from "../libraries/variables";
// STYLES
const styles = StyleSheet.create({
  // APP
  appContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    overflow: "hidden",
  },
  appBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
    overflow: "hidden",
  },
  // HEADER
  header: {
    height: 80,
    width: "100%",
    position: "absolute",
    top: 30,
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 10,
  },
  // STATS
  statsContainer: {
    height: 50,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  // LEVEL
  levelContainer: {
    height: "100%",
    width: 90,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.red,
    borderWidth: 3,
    borderColor: colors.white,
    borderRadius: 10,
  },
  levelTitle: {
    fontSize: 21,
    color: colors.white,
  },
  // TIME
  timeContainer: {
    height: 20,
    width: 60,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white,
    borderRadius: 15,
  },
  timeImage: {
    height: 30,
    width: 30,
    position: "absolute",
    left: -15,
  },
  timeText: {
    fontSize: 17,
    color: colors.black,
  },
  // POINTS
  pointsContainer: {
    height: 20,
    width: 60,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white,
    borderRadius: 15,
  },
  pointsImage: {
    height: 30,
    width: 30,
    position: "absolute",
    left: -15,
  },
  pointsText: {
    fontSize: 17,
    color: colors.black,
  },
  // PAUSE
  pauseButton: {
    height: 40,
    width: 40,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.black,
    borderWidth: 3,
    borderColor: colors.white,
    borderRadius: 10,
  },
  pauseButtonImage: {
    height: 15,
    width: 15,
  },
  // HEALTH
  healthContainer: {
    height: 20,
    width: "80%",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: colors.white,
    borderRadius: 15,
    padding: 2,
    marginLeft: 25,
  },
  healthIcon: {
    height: 35,
    width: 35,
    position: "absolute",
    left: -17.5,
    zIndex: 2,
    resizeMode: "contain",
  },
  healthBar: {
    height: "100%",
    backgroundColor: colors.red,
    borderRadius: 15,
  },
  // GAME
  gameContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    overflow: "hidden",
  },
  playRow: {
    height: 100,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  playCell: {
    height: 100,
    width: "33.33%",
    alignItems: "center",
    justifyContent: "center",
  },
});
// EXPORT
export { styles };
