// IMPORTS
import React, { Component } from "react";
import {
  View,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
} from "react-native";
import { registerRootComponent } from "expo";
import { sprites } from "./libraries/variables";
import { randomBetween } from "./libraries/methods";
import { styles } from "./styles/appStyles";
import Mole from "./components/Mole";
import Menu from "./components/Menu";
import Background from "./assets/backgrounds/field.png";
// STATE
const STATE = {
  level: 1,
  health: 100,
  points: 0,
  time: 10,
  cleared: false,
  paused: false,
  gameOn: false,
  gameover: false,
};
// COMPONENT
export default class App extends Component {
  constructor(props) {
    super(props);
    this.moles = [];
    this.spawnLoop = null;
    this.timeLoop = null;
    this.state = STATE;
  }
  // LIFE CYCLE
  componentDidMount = () => {
    clearInterval(this.spawnLoop);
    clearInterval(this.timeLoop);
    this.setState({ paused: true });
  };
  // GAME LOOP
  gameLoop = () => {
    let speed = 750 - this.state.level * 50;
    if (speed < 350) {
      speed = 350;
    }
    // SPAWN LOOP
    this.spawnLoop = setInterval(() => {
      if (this.moles.length > 12) {
        return;
      }
      let randomIndex = randomBetween(0, 11);
      if (
        this.moles[randomIndex].isPopping ||
        this.moles[randomIndex].hasPopped
      ) {
        return;
      } else {
        this.moles[randomIndex].pop();
      }
    }, speed);
    // TIME LOOP
    this.timeLoop = setInterval(() => {
      if (this.state.time <= 0) {
        clearInterval(this.spawnLoop);
        this.moles.map(
          (mole) => (mole.isPopping || mole.hasPopped) && mole.hide()
        );
        clearInterval(this.timeLoop);
        this.setState({ cleared: true });
      } else {
        this.setState({ time: this.state.time - 1 });
      }
    }, 1000);
  };
  // MENU METHODS
  menuMethods = {
    start: () => {
      this.setState({ gameOn: true, paused: false }, this.gameLoop);
    },
    restart: () => {
      this.moles.map(
        (mole) => (mole.isPopping || mole.hasPopped) && mole.hide()
      );
      this.setState({ ...STATE, gameOn: true }, this.gameLoop);
    },
    play: () => {
      this.moles.map((mole) => mole.frozen && mole.unfreeze());
      this.setState({ paused: false }, this.gameLoop);
    },
    pause: () => {
      clearInterval(this.spawnLoop);
      this.moles.map((mole) => mole.freeze());
      clearInterval(this.timeLoop);
      this.setState({ paused: true });
    },
    nextLevel: () => {
      this.setState(
        {
          level: this.state.level + 1,
          time: STATE.time,
          cleared: false,
        },
        this.gameLoop
      );
    },
  };
  // DAMAGE PLAYER
  damagePlayer = () => {
    if (this.state.cleared || this.state.gameOver || this.state.paused) {
      return;
    }
    let currentHealth = this.state.health - 10 < 0 ? 0 : this.state.health - 10;
    this.setState({ health: currentHealth });
    if (currentHealth <= 0) {
      clearInterval(this.spawnLoop);
      this.moles.map(
        (mole) => (mole.isPopping || mole.hasPopped) && mole.hide()
      );
      clearInterval(this.timeLoop);
      this.setState({ gameover: true });
    }
  };
  // RENDER
  render() {
    return (
      <View style={styles.appContainer}>
        {/* BACKGROUND */}
        <ImageBackground style={styles.appBackground} source={Background} />
        {/* HEADER */}
        <View style={styles.header}>
          {/* STATS */}
          <View style={styles.statsContainer}>
            {/* LEVEL */}
            <View style={styles.levelContainer}>
              <Text style={styles.levelTitle}>Level {this.state.level}</Text>
            </View>
            {/* TIME */}
            <View style={styles.timeContainer}>
              <Image style={styles.timeImage} source={sprites.clock} />
              <Text style={styles.timeText}>{this.state.time}</Text>
            </View>
            {/* POINTS */}
            <View style={styles.pointsContainer}>
              <Image style={styles.pointsImage} source={sprites.coin} />
              <Text style={styles.pointsText}>{this.state.points}</Text>
            </View>
            {/* PAUSE BUTTON */}
            <TouchableOpacity
              style={styles.pauseButton}
              onPress={this.menuMethods.pause}
            >
              <Image style={styles.pauseButtonImage} source={sprites.pause} />
            </TouchableOpacity>
          </View>
          {/* HEALTH BAR */}
          <View style={styles.healthContainer}>
            <Image style={styles.healthIcon} source={sprites.heart} />
            <View
              style={[styles.healthBar, { width: `${this.state.health}%` }]}
            />
          </View>
        </View>
        {/* GAME */}
        <View style={styles.gameContainer}>
          {Array.apply(null, Array(4)).map((_, rowIdx) => {
            return (
              <View style={styles.playRow} key={rowIdx}>
                {Array.apply(null, Array(3)).map((_, colIdx) => {
                  let moleIdx = rowIdx * 3 + colIdx;
                  return (
                    <View style={styles.playCell} key={colIdx}>
                      <Mole
                        index={moleIdx}
                        ref={(ref) => {
                          this.moles[moleIdx] = ref;
                        }}
                        paused={
                          this.state.paused ||
                          this.state.cleared ||
                          this.state.gameover
                        }
                        damagePlayer={this.damagePlayer}
                        addPoint={() =>
                          this.setState({ points: this.state.points + 1 })
                        }
                      />
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
        {/* MENU */}
        {(this.state.cleared || this.state.gameover || this.state.paused) && (
          <Menu
            props={{
              title: !this.state.gameOn
                ? "Ready ?"
                : this.state.cleared
                ? "Cleared"
                : this.state.gameover
                ? "Game Over"
                : this.state.paused && "Paused",
              level: this.state.level,
              points: this.state.points,
              menuMethods: this.menuMethods,
            }}
          />
        )}
      </View>
    );
  }
}
// INITIALIZE
registerRootComponent(App);
