// IMPORTS
import { StyleSheet } from "react-native";
import { colors } from "../libraries/variables";
// STYLES
const styles = StyleSheet.create({
  // MOLE
  moleButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  // MENU
  menu: {
    height: 250,
    width: 250,
    position: "absolute",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: colors.blackFade,
    borderRadius: 15,
  },
  // TITLE
  menuTitle: {
    fontSize: 45,
    fontWeight: "700",
  },
  // TEXT
  menuText: {
    fontSize: 30,
    color: colors.white,
    fontWeight: "500",
    textAlign: "center",
  },
  // BUTTONS
  menuButtons: {
    height: 60,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  menuButton: {
    height: 60,
    width: 70,
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: colors.red,
    borderWidth: 5,
    borderColor: colors.white,
    borderRadius: 10,
  },
  menuButtonImage: {
    height: 20,
    width: 20,
  },
  menuButtonText: {
    width: 50,
    color: colors.white,
    fontSize: 15,
    fontWeight: "400",
    textAlign: "center",
  },
});
// EXPORT
export { styles };
