// IMPORTS
import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { colors, sprites } from "../libraries/variables";
import { styles } from "../styles/componentStyles";
// COMPONENT
const Menu = ({ props }) => {
  // STATE
  const { title, level, points, menuMethods } = props;
  const { start, restart, play, nextLevel } = menuMethods;
  // RENDER
  return (
    <View style={styles.menu}>
      {/* TITLE */}
      <Text
        style={[
          styles.menuTitle,
          { color: title === "Game Over" ? colors.red : colors.white },
        ]}
      >
        {title}
      </Text>
      {/* LEVEL */}
      {title === "Game Over" && (
        <Text style={styles.menuText}>Level {level}</Text>
      )}
      {/* POINTS */}
      {title === "Game Over" && (
        <Text style={styles.menuText}>Score {points}</Text>
      )}
      {/* BUTTONS */}
      <View style={styles.menuButtons}>
        {/* RESTART */}
        {(title === "Paused" || title === "Game Over") && (
          <TouchableOpacity style={styles.menuButton} onPress={restart}>
            <Image style={styles.menuButtonImage} source={sprites.restart} />
            <Text style={styles.menuButtonText}>Restart</Text>
          </TouchableOpacity>
        )}
        {/* PLAY */}
        {(title === "Paused" || title === "Cleared" || title === "Ready ?") && (
          <TouchableOpacity
            style={styles.menuButton}
            onPress={() =>
              title === "Cleared"
                ? nextLevel()
                : title === "Ready ?"
                ? start()
                : play()
            }
          >
            <Image style={styles.menuButtonImage} source={sprites.play} />
            <Text style={styles.menuButtonText}>
              {title === "Cleared" ? "Next" : "Play"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};
// EXPORT
export default Menu;
