// IMPORTS
import { Dimensions } from "react-native";
// SCREEN
const screen = {
  height: Dimensions.get("screen").height,
  width: Dimensions.get("screen").width,
};
// COLORS
const colors = {
  white: "#ffffff",
  whiteFade: "rgba(255, 255, 255, 0.5)",
  black: "#000000",
  blackFade: "rgba(0, 0, 0, 0.7)",
  gray: "#888888",
  red: "#ff1a1a",
  phone: "#1d1d1d",
  phoneLense: "#1f3a61",
  phoneSpeaker: "#161616",
  phoneBar: "#505050",
};
// SPRITES
const sprites = {
  clock: require("../assets/sprites/clock.png"),
  coin: require("../assets/sprites/coin.png"),
  heart: require("../assets/sprites/heart.png"),
  moles: require("../assets/sprites/moles.png"),
  pause: require("../assets/sprites/pause.png"),
  play: require("../assets/sprites/play.png"),
  restart: require("../assets/sprites/restart.png"),
};
// EXPORTS
export { screen, colors, sprites };
